import { useEffect, useState } from "react";
import { URLS } from "utils/urls";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import "./header.scss";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { CitySelect, CountrySelect, StateSelect } from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";




export const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isInvestorMenuOpen, setIsInvestorMenuOpen] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [confirm_passwd, setConfirmPasswd] = useState("");


  const toggleIsMenuOpen = (e) => {
    e.preventDefault();
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleInvestorMenu = () => {
    setIsInvestorMenuOpen(!isInvestorMenuOpen);
  };


  // const apiURL = process.env.BASE_URL;
  
  const handleLogin = async (e) => {
    e.preventDefault();
    
    if(!email || !password) { Swal.fire({ icon: 'error', title: 'Try again', text: 'Please provide your email address and password to proceed' }); return; }

    const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;

    if (!emailRegex.exec(email)) { Swal.fire({ icon: 'error', title: 'Try again', text: 'Invalid Email entered' }); return; }

    if (password.length < 8) { Swal.fire({ icon: 'error', title: 'Try again', text: 'Password cannot be less than 8 characters.' }); return; }
    
    try {
      setLoading(true);

      const response = await axios.post("https://paysprint.ca/api/v1/investorlogin", { email, password, }, {
        headers: { Authorization: "Bearer base64:JFM+PJaWD/pBypX+NhXudDrAmianZdGYZ41qz4WhXL0=" }
      });

      const { token } = response.data;
      const expirationTime = new Date().getTime() + 60 * 60 * 1000; // Expire's in  1hr
      const authData = { token, expirationTime };

      if (response.data.status === 200) {
        setLoading(false);
        
        const result = response.data;
        // console.log(result);

        localStorage.setItem("user", JSON.stringify(result.data));
        localStorage.setItem("authData", JSON.stringify(authData));
        setUser(result.data);
        setIsLoggedIn(true); // Mark user as logged in

        Swal.fire({ icon: 'success', title: 'Welcome Back!', text: 'Hello ' + result.data.name + ' you\'re welcome to PaySprint Investor Program ', timer: 5000 })
        .then (() => {
          // window.location.reload();
          window.location.href = "/opportunities";
        });

      }

    } catch (error) {
  
      setLoading(false);
      const errorMessage = error.response?.data?.message || error.message;

      console.error("Login failed:", errorMessage);
      Swal.fire({ icon: 'error', title: 'Login Failed!', text: errorMessage })
    } 

  };


  const handleRegister = async (e) => {
    e.preventDefault();
    
    if (!name || !email || !phone || !country || !state || !city || !password) {
      Swal.fire({ icon: "error", title: "Missing Information", text: "Please fill out all fields to proceed.", }); return;
    }

    const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;

    if (!emailRegex.exec(email)) { Swal.fire({ icon: 'error', title: 'Try again', text: 'Invalid Email entered' }); return; }

    if (password.length > 0 && password.length < 8) { Swal.fire({ icon: 'error', title: 'Try again', text: 'Password cannot be less than 8 characters.' }); return; }

    if (phone.length < 11) {
      Swal.fire({ icon: "error", title: "Invalid Phone Number", text: "Phone number must be at least 11 digits long.", }); return;
    }
    
    try {
      setLoading(true);

      let getCountry = country.name;
      let getState = state.name;
      let getCity = city.name;

      const response = await axios.post( "https://paysprint.ca/api/v1/investor-register", { name, email, phone, getCountry, getState, getCity, password, }, {
          headers: { Authorization: "Bearer base64:JFM+PJaWD/pBypX+NhXudDrAmianZdGYZ41qz4WhXL0=", }, }
      );

    const { data, status } = response.data;


    if (status === 200) {
      setLoading(false);

      Swal.fire({ icon: "success", title: "Registration Successful!", text: `Welcome, ${data.name}!. Please check your email box alongside your spam folder in other to verify your account.` });
      // .then (() => {
      //   window.location.reload();
      // });

    }

    } catch (error) {
  
      setLoading(false);
      const errorMessage = error.response?.data?.message || error.message;
      // console.log(errorMessage);
      Swal.fire({ icon: 'error', title: 'Login Failed!', text: errorMessage })
    } 

  };


  const handleUpdateProfile = async (e) => {
    e.preventDefault();
    
    if (!name || !email || !phone || !password || !confirm_passwd) {
      Swal.fire({ icon: "error", title: "Missing Information", text: "Please fill out all fields to proceed.", }); return;
    }

    const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;

    if (!emailRegex.exec(email)) { Swal.fire({ icon: 'error', title: 'Try again', text: 'Invalid Email entered' }); return; }

    if (phone.length < 11) {
      Swal.fire({ icon: "error", title: "Invalid Phone Number", text: "Phone number must be at least 11 digits long.", }); return;
    }

    if (password.length > 0 && password.length < 8) { Swal.fire({ icon: 'error', title: 'Try again', text: 'Password cannot be less than 8 characters.' }); return; }

    if (password !== confirm_passwd) { Swal.fire({ icon: 'error', title: 'Try again', text: 'Password and Confirm Password fields did not match' }); return; }
    
    try {
      setLoading(true);

      let token = user.apiToken;

      const response = await axios.post( "https://paysprint.ca/api/v1/investor-update-profile", { name, email, phone, password, token }, {
          headers: { Authorization: "Bearer base64:JFM+PJaWD/pBypX+NhXudDrAmianZdGYZ41qz4WhXL0=", }, }
      );

    const { data, status } = response.data;

    
    if (status === 200) {

      const { token } = data;
      const expirationTime = new Date().getTime() + 60 * 60 * 1000;
      const authData = { token, expirationTime };

      localStorage.setItem("user", JSON.stringify(data));
      localStorage.setItem("authData", JSON.stringify(authData));

      setUser(data);
      setLoading(false);

      Swal.fire({ icon: "success", title: "Successfully Updated", text: `Hey, ${data.name}! You've successfully updated your profile`, timer: 5000 })
      .then (() => {
        window.location.reload();
      });

    }

    } catch (error) {
  
      setLoading(false);
      const errorMessage = error.response?.data?.message || error.message;
      Swal.fire({ icon: 'error', title: 'Profile Update Failed!', text: errorMessage })
    } 

  };
  
  const handleLogout = async () => {
    try {
      const authData = JSON.parse(localStorage.getItem("authData"));
      if (!authData || !user) {
        Swal.fire({ icon: "error", title: "Error", text: "No active user found" });
        return;
      }
  
      localStorage.removeItem("user");
      localStorage.removeItem("authData");
      setUser(null);
      setIsLoggedIn(false);
  
      Swal.fire({ icon: "success", title: "Success!", text: "You have been successfully logged out", timer: 3000 }).then(() => { window.location.href = "/"; });
  
    } catch (error) {
      console.error("Logout failed:", error.response?.data?.message || error.message);
      Swal.fire({ icon: "error", title: "Logout Failed!", text: "Something went wrong. Try again." });
    }

  };


  const location = useLocation();
  const pathname = location.pathname;
  

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    const storedAuthData = localStorage.getItem("authData");

    if (storedUser && storedAuthData) {
      const authData = JSON.parse(storedAuthData);
      const currentTime = new Date().getTime();

      if (authData.expirationTime > currentTime) {
        setIsLoggedIn(true); // User is still logged in
        setUser(JSON.parse(storedUser)); // Set user data
      } else {
        // Clear expired data
        localStorage.removeItem("user");
        localStorage.removeItem("authData");
        setIsLoggedIn(false);
      }
    }
  }, [isLoggedIn]);




  return (
    <>
      <div className="mobile-nav">
        <header>
          <Link to="/">
            <img className="logo" src={URLS.paySprintLogo} alt="paysprint logo" />
          </Link>

          <button onClick={toggleIsMenuOpen}>
            <span className={`first ${isMenuOpen ? "open" : ""}`} />
            <span className={`second ${isMenuOpen ? "open" : ""}`} />
            <span className={`third ${isMenuOpen ? "open" : ""}`} />
          </button>
        </header>

        <nav className={`menu ${isMenuOpen ? "open" : ""}`}>
            {user ? (
              <ul>
                <li> <Link to="/" className={pathname === '/' ? 'active' : ''} > HOME </Link> </li>
                <li> <a href="#newslist"> Investor News </a> </li>
                <li className="investor"> 
                  <li> <a href="/opportunities" className={pathname === '/opportunities' ? 'active' : ''} > Opportunity </a> </li>  
                  <div className="subMenu">
                    <a href="#oppotunitylist"> New </a>
                    <a href="#archive"> Archive </a>
                  </div>
                </li>
                <li className="investor">
                  <li>
                    <p> <span> Hey </span> {user.name}, </p>
                  </li>
                  <div className="subMenu">
                    <a href="#logout"> Logout </a>
                  </div>
                </li>
              </ul>
            ) : (
              <ul>
                <li> <Link to="/"> HOME </Link> </li>
                <li> <a href="#newslist"> Investor News </a> </li>
                <li> <a href="https://paysprint.ca/about"> ABOUT Us </a> </li>
                <li className="investor">
                  <li>
                    <Link to="/"> {" "} Get Started <FontAwesomeIcon onClick={toggleInvestorMenu} icon={isInvestorMenuOpen ? faAngleUp : faAngleDown} />{" "} </Link>
                  </li>
                  <div className="subMenu">
                    <a href="#register" data-bs-toggle="modal" data-bs-target="#registrationModal"> SIGNUP </a>
                    <a href="#login" data-bs-toggle="modal" data-bs-target="#exampleModal"> LOGIN </a>
                  </div>
                </li>
                <li> <a href="https://paysprint.ca/contact"> CONTACT US </a> </li>
              </ul>
            )}
        </nav>

      </div>

      <div className="desktop-nav">
        <div className="left">
          <Link to="/">
            <img src={URLS.paySprintLogo} alt="paysprint logo" />
          </Link>
        </div>
        <div className="right">
          <nav>

          {user ? (
            <ul>
              <li> <Link to="/" className={pathname === '/' ? 'active' : ''} > HOME </Link> </li>
              <li> <a href="#newslist"> Investor News </a> </li>
              <li className="investor"> 
                <li> <a href="/opportunities" className={pathname === '/opportunities' ? 'active' : ''} > Opportunity </a> </li>  
                <div className="subMenu">
                  <a href="#oppotunitylist"> New </a>
                  <a href="#archive"> Archive </a>
                </div>
              </li>
              <li className="investor">
                <li> <p> <span> Hey </span> {user.name}, </p> </li>
                <div className="subMenu"> 
                  <a href="#profile" data-bs-toggle="modal" data-bs-target="#settingsModal"> Profile </a> 
                  <a href="#logout" onClick={handleLogout}> Logout </a> 
                </div>
              </li>
            </ul>

          ) : (
            <ul>
              <li> <Link to="/"> HOME </Link> </li>
              <li> <a href="#newslist"> Investor News </a> </li>
              <li> <a href="https://paysprint.ca/about"> ABOUT Us </a> </li>
              <li className="investor">
                <li> <Link to="/"> {" "} Get Started <FontAwesomeIcon onClick={toggleInvestorMenu} icon={isInvestorMenuOpen ? faAngleUp : faAngleDown} />{" "} </Link> </li>
                <div className="subMenu">
                  <a href="#register" data-bs-toggle="modal" data-bs-target="#registrationModal"> SIGNUP </a>
                  <a href="#login" data-bs-toggle="modal" data-bs-target="#exampleModal"> LOGIN </a>
                </div>
              </li>
              <li> <a href="https://paysprint.ca/contact"> CONTACT US </a> </li>
            </ul>
          )}

          </nav>
        </div>
      </div>

      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">

            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel"> Login Here </h1>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>

            <div class="modal-body">
              <form id="investorRelationModal">
                <div class="mb-3">
                  <label for="email" class="col-form-label"> Email Address: </label>
                  <input type="email" class="form-control" id="email" name="email" placeholder="jonny@info.com" required value={email} onChange={(e) => setEmail(e.target.value)} />
                </div>
                <div class="mb-3">
                  <label for="password" class="col-form-label"> Password: </label>
                  <input type="password" class="form-control" id="password" name="password" placeholder="******" required value={password} onChange={(e) => setPassword(e.target.value)} />
                </div>

                <div class="g-recaptcha" data-sitekey="6LfQocYqAAAAABeLUwOnJ-oWMVAUqVHvJsYrpwGF"></div>

                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"> Close </button>
                  <button type="submit" id="submitBtn" class="btn btn-primary" disabled={loading} onClick={handleLogin}>
                      {loading ? ( <> <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> </> ) : (  "Submit" )}
                  </button>
                </div>

                <div>
                  <p> Don't have an account yet? <a href="#register" data-bs-toggle="modal" data-bs-target="#registrationModal"> Create Account </a> </p>
                </div>
              </form>
            </div>
            </div>
        </div>
      </div>

      <div class="modal fade" id="registrationModal" tabindex="-1" aria-labelledby="registrationModallabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">

            <div class="modal-header">
              <h1 class="modal-title fs-5" id="registrationModallabel"> Please fill in your correct details  </h1>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>

            <div class="modal-body">
              <form id="investorRelationModal">
                <div class="mb-3">
                  <label for="name" class="col-form-label"> Full Name: </label>
                  <input type="text" class="form-control" id="name" name="name" placeholder="Johnny Dave" required value={name} onChange={(e) => setName(e.target.value)} />
                </div>

                <div class="mb-3">
                  <label for="email" class="col-form-label"> Email Address: </label>
                  <input type="email" class="form-control" id="email" name="email" placeholder="info@jonny.com" required value={email} onChange={(e) => setEmail(e.target.value)} />
                </div>

                <div class="mb-3">
                  <label for="phone" class="col-form-label"> Phone Number </label>
                  <input type="text" class="form-control" id="phone" name="phone" placeholder="+1-234-567-890" required value={phone} onChange={(e) => setPhone(e.target.value)} />
                </div>

                <div class="row">
                  <div class="col-sm-6">
                    <label for="country"> Select Country </label>
                    <CountrySelect name="country" id="country" containerClassName="form-group" onChange={(_country) => setCountry(_country)} onTextChange={(_txt) => console.log(_txt)} placeHolder="Select Country"/>
                  </div>
                  <div class="col-sm-6">
                    <label for="country"> State/Province </label>
                    <StateSelect name="state" id="state" countryid={country?.id} containerClassName="form-group" onChange={(_state) => setState(_state)} onTextChange={(_txt) => console.log(_txt)} defaultValue={state} />
                  </div>
                </div>

                <div class="mb-3">
                  <label for="city" class="col-form-label"> City </label>
                  <CitySelect name="city" id="city" countryid={country?.id} stateid={state?.id} containerClassName="form-group" onChange={(_city) => setCity(_city)} onTextChange={(_txt) => console.log(_txt)} defaultValue={city} />
                </div>

                <div class="mb-3">
                  <label for="password" class="col-form-label"> Password: </label>
                  <input type="password" class="form-control" id="password" name="password" placeholder="******" required value={password} onChange={(e) => setPassword(e.target.value)} />
                </div>

                <div class="g-recaptcha" data-sitekey="6LfQocYqAAAAABeLUwOnJ-oWMVAUqVHvJsYrpwGF"></div>

                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"> Close </button>
                  <button type="submit" id="submitBtn" class="btn btn-primary" disabled={loading} onClick={handleRegister}>
                      {loading ? ( <> <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> </> ) : (  "Submit" )}
                  </button>
                </div>

                <div>
                  <p> Already have an account? <a href="#login" data-bs-toggle="modal" data-bs-target="#exampleModal"> Login here </a> </p>
                </div>
              </form>
            </div>
            </div>
        </div>
      </div>

      <div class="modal fade" id="settingsModal" tabindex="-1" aria-labelledby="settingsModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">

            <div class="modal-header">
              <h1 class="modal-title fs-5" id="settingsModalLabel"> Update Your Profile  </h1>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>

            <div class="modal-body">
              <form id="investorRelationModal">
                <div class="mb-3">
                  <label for="name" class="col-form-label"> Full Name: </label>
                  <input type="text" class="form-control" id="name" name="name" placeholder={user ? user.name : ''} required value={name} onChange={(e) => setName(e.target.value)} />
                </div>

                <div class="mb-3">
                  <label for="email" class="col-form-label"> Email Address: </label>
                  <input type="email" class="form-control" id="email" name="email" placeholder={user ? user.email : ''} required value={email} onChange={(e) => setEmail(e.target.value)} />
                </div>

                <div class="mb-3">
                  <label for="phone" class="col-form-label"> Phone Number </label>
                  <input type="number" class="form-control" id="phone" name="phone" placeholder={user ? user.phoneNumber : ''} required value={phone} onChange={(e) => setPhone(e.target.value)} />
                </div>

                <div class="mb-3">
                  <label for="password" class="col-form-label"> Password: </label>
                  <input type="password" class="form-control" id="password" name="password" placeholder="******" required value={password} onChange={(e) => setPassword(e.target.value)} />
                </div>
                
                <div class="mb-3">
                  <label for="confirm_passwd" class="col-form-label"> Confirm Password: </label>
                  <input type="password" class="form-control" id="confirm_passwd" name="confirm_passwd" placeholder="******" required value={confirm_passwd} onChange={(e) => setConfirmPasswd(e.target.value)} />
                </div>

                <div class="g-recaptcha" data-sitekey="6LfQocYqAAAAABeLUwOnJ-oWMVAUqVHvJsYrpwGF"></div>

                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"> Close </button>
                  <button type="submit" id="submitBtn" class="btn btn-primary" disabled={loading} onClick={handleUpdateProfile}>
                      {loading ? ( <> <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> </> ) : (  "Submit" )}
                  </button>
                </div>
              </form>
            </div>
            </div>
        </div>
      </div>
    </>
  );
};
